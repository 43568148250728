import { applyMiddleware, createStore } from "redux";

const initialState = {
  isLoggedIn: false,
  uploadPosterId: null,
  uploadBannerIds: [],
  UploadHotelBookingIds: [],
  tourGuidanceIds: [],
  tourCategoryData: [],
  allToursData: [],
  selectedTour: {},
  selectedActiveTab: "",
  confirmationData: [],
  uploadVideoId: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
      };
    case "SET_UPLOAD_POSTER_ID":
      return {
        ...state,
        uploadPosterId: action.payload,
      };
    case "SET_UPLOAD_BANNER_IDS":
      return {
        ...state,
        uploadBannerIds: action.payload,
      };
    case "SET_HOTEL_BOOKING_IDS":
      return {
        ...state,
        UploadHotelBookingIds: action.payload,
      };
    case "SET_TOUR_GUIDANCE_IDS":
      return {
        ...state,
        tourGuidanceIds: action.payload,
      };
    case "SET_TOUR_CATEGORY_DATA":
      return {
        ...state,
        tourCategoryData: action.payload,
      };
    case "SET_ALL_TOURS_DATA":
      return {
        ...state,
        allToursData: action.payload,
      };
    case "SET_SELECTED_TOUR":
      return {
        ...state,
        selectedTour: action.payload,
      };
    case "SET_SELECTED_ACTIVE_TAB":
      return {
        ...state,
        selectedActiveTab: action.payload,
      };
    case "SET_CONFIRMATION_DATA":
      return {
        ...state,
        confirmationData: action.payload,
      };
    case "SET_UPLOAD_VIDEO_ID":
      return {
        ...state,
        uploadVideoId: action.payload,
      };
    case "RESET_UPLOAD_FIELDS":
      return {
        ...state,
        uploadPosterId: null,
        uploadBannerIds: [],
      };

    default:
      return state;
  }
};

const navigationMiddleware = (store) => (next) => (action) => {
  if (action.type === "NAVIGATE") {
    store.dispatch({ type: "RESET_UPLOAD_FIELDS" });
  }
  return next(action);
};

const store = createStore(authReducer, applyMiddleware(navigationMiddleware));

export { store };
