import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="spinner">
            <div className="spinner-circle"></div>
          </div>
        }
      >
        <App />
        </Suspense>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
